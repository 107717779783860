/* eslint-disable @typescript-eslint/ban-ts-comment */
import type {FC} from 'react';
import React, {useEffect} from 'react';
import {Portal, Stack, TextField} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import type {PublicConnectDTO} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {Gateway} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {
  useCreatePaymentGatewayConnections,
  useUpdatePaymentGatewayConnection,
} from '@local/frontend/libs/trpc/trpc';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import {useNotification} from '@local/frontend/hooks/useNotification';
import FormSectionTitle from '@local/frontend/components/atoms/FormSectionTitle';
import {Currency} from '@handsin/money';
import type {GatewayConfigurationProps} from './common/GatewayConfigurationProps';
import GenericFormSection from './common/GenericFormSection';

const createBridgerPayConnectionSchema = Yup.object({
  name: Yup.string().optional(),
  supportedCurrencies: Yup.array(
    Yup.mixed<Currency>().oneOf(Object.values(Currency)).required()
  ).optional(),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  api_key: Yup.string().required('Api key is required'),
  cashier_key: Yup.string().required('Cashier key is required'),
}).optional();

const updateBridgerPayConnectSchema = Yup.object({
  name: Yup.string().optional(),
  supportedCurrencies: Yup.array(
    Yup.mixed<Currency>().oneOf(Object.values(Currency)).required()
  ).optional(),
});

const BridgerPayUpdateConfiguration: FC<
  React.PropsWithChildren<
    GatewayConfigurationProps & {connectRecord: PublicConnectDTO}
  >
> = ({connectRecord, actionAreaRef, paymentGateway, setConnectRecord}) => {
  const {open: openNotification} = useNotification();
  const updateConnectionMutation = useUpdatePaymentGatewayConnection({
    onSuccess: newConnectRecord => {
      openNotification({
        message: `Successfully updated ${paymentGateway}!`,
        severity: 'success',
      });
      setConnectRecord(newConnectRecord);
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          `Failed to update ${paymentGateway}!`,
        severity: 'error',
      });
    },
  });

  const {
    formState: {
      isDirty: updateIsDirty,
      isValid: updateIsValid,
      isSubmitting: updateIsSubmitting,
    },
    ...updateConnectionFormMethods
  } = useForm<Yup.InferType<typeof updateBridgerPayConnectSchema>>({
    mode: 'all',
    resolver: yupResolver(updateBridgerPayConnectSchema),
    defaultValues: {
      name: '',
      supportedCurrencies: [],
    },
  });

  useEffect(() => {
    updateConnectionFormMethods.reset({
      name: connectRecord?.name ?? '',
      supportedCurrencies: connectRecord?.supportedCurrencies ?? [],
    });
  }, [connectRecord]);

  const onUpdateConnectionFormSubmit = (
    formData: Yup.InferType<typeof updateBridgerPayConnectSchema>
  ) => {
    updateConnectionMutation.mutate({
      gateway: connectRecord.gatewayName,
      gatewayId: connectRecord.gatewayId,
      updateParams: {
        name: formData.name,
        supportedCurrencies: formData.supportedCurrencies,
      },
    });
  };

  return (
    <form>
      <Stack direction="column">
        <GenericFormSection control={updateConnectionFormMethods.control} />
      </Stack>
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={updateConnectionFormMethods.handleSubmit(
            onUpdateConnectionFormSubmit
          )}
          disabled={!updateIsDirty || !updateIsValid || updateIsSubmitting}
          loading={updateIsSubmitting || updateConnectionMutation.isLoading}
          variant="outlined"
        >
          Update
        </LoadingButton>
      </Portal>
    </form>
  );
};

const BridgerPayCreateCofiguration: FC<
  React.PropsWithChildren<GatewayConfigurationProps>
> = ({actionAreaRef, paymentGateway, setConnectRecord}) => {
  const {open: openNotification} = useNotification();
  const createConnectionMutation = useCreatePaymentGatewayConnections({
    onSuccess: newConnectRecord => {
      openNotification({
        message: `Successfully connected to ${paymentGateway}!`,
        severity: 'success',
      });
      setConnectRecord(newConnectRecord);
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          `Failed  to connect to ${paymentGateway}!`,
        severity: 'error',
      });
    },
  });

  const {
    formState: {isValid: createIsValid, isSubmitting: createIsSubmitting},
    ...createConnectionFormMethods
  } = useForm<Yup.InferType<typeof createBridgerPayConnectionSchema>>({
    mode: 'all',
    resolver: yupResolver(createBridgerPayConnectionSchema),
    defaultValues: {
      name: '',
      username: '',
      password: '',
      supportedCurrencies: [],
    },
  });

  const onCreateConnectionFormSubmit = (
    formData: Yup.InferType<typeof createBridgerPayConnectionSchema>
  ) => {
    createConnectionMutation.mutate({
      gateway: Gateway.BRIDGERPAY,
      name: formData?.name,
      supportedCurrencies: formData.supportedCurrencies,
      auth: {
        'bridger-pay': {
          username: formData.username,
          password: formData.password,
          api_key: formData.api_key,
          cashier_key: formData.cashier_key,
        },
      },
    });
  };

  return (
    <form>
      <Stack direction="column">
        {/* @ts-expect-error */}
        <GenericFormSection control={createConnectionFormMethods.control} />

        <FormSectionTitle
          title="Authentication"
          helper="Please provide your username and password to authenticate to BridgerPay"
        />
        <Stack spacing={1}>
          <Controller
            name="username"
            control={createConnectionFormMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                required
                label="Username"
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={createConnectionFormMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                required
                label="Password"
                type="password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="api_key"
            control={createConnectionFormMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                required
                label="API key"
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="cashier_key"
            control={createConnectionFormMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                required
                label="Cashier key"
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Stack>
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={createConnectionFormMethods.handleSubmit(
            onCreateConnectionFormSubmit
          )}
          disabled={!createIsValid || createIsSubmitting}
          loading={createIsSubmitting || createConnectionMutation.isLoading}
          variant="outlined"
        >
          Connect
        </LoadingButton>
      </Portal>
    </form>
  );
};

const BridgerPayConfiguration: FC<
  React.PropsWithChildren<GatewayConfigurationProps>
> = ({connectRecord, ...props}) => {
  if (!connectRecord) {
    return <BridgerPayCreateCofiguration {...props} />;
  }

  return (
    <BridgerPayUpdateConfiguration {...props} connectRecord={connectRecord} />
  );
};

export default BridgerPayConfiguration;
