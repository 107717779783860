import type {Country} from '@handsin/api-node';
import React from 'react';
import {Gateway} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {Typography} from '@mui/material';
import ShuttleConfiguration from './configurations/ShuttleConfiguration';
import PlutoConfiguration from './configurations/PlutoConfiguration';
import type {GatewayConfigurationProps} from './configurations/common/GatewayConfigurationProps';
import SquareUpdateConfiguration from './configurations/SquareUpdateConfiguration';
import {OauthConfiguration} from './configurations/common/OauthConfiguration';
import StripeUpdateConfiguration from './configurations/StripeUpdateConfiguration';
import BridgerPayConfiguration from './configurations/BridgerPayConfiguration';

export interface ServiceOption {
  service: Gateway;
  displayName: string;

  iconUrl: string;
  description?: string;
  fulfilledBy?: string;
  private?: boolean;

  supportedCountries: Country[];
  supportsGooglePay: boolean;
  supportsApplePay: boolean;

  appReadMe: string;
  ConfigurationComponent: (props: GatewayConfigurationProps) => JSX.Element;
  categories?: string[];
  home?: string;
  sources?: string[];
}

export const gatewayToServiceOptions: Record<Gateway, ServiceOption> = {
  [Gateway.STRIPE]: {
    displayName: 'Stripe',
    fulfilledBy: 'By Stripe Inc',
    service: Gateway.STRIPE,
    iconUrl: 'https://cdn.iconscout.com/icon/free/png-256/stripe-2-498440.png',
    supportedCountries: [],
    supportsGooglePay: true,
    supportsApplePay: true,
    ConfigurationComponent: ({connectRecord, ...props}) => {
      if (!connectRecord) {
        return <OauthConfiguration {...props} />;
      }

      return (
        <StripeUpdateConfiguration {...props} connectRecord={connectRecord} />
      );
    },
    appReadMe:
      '# Stripe\nStripe is a widely used online payment processing platform that enables businesses to accept payments over the internet. It provides a secure and seamless way to handle transactions, making it a popular choice for e-commerce websites, mobile apps, and subscription-based services. With Stripe, businesses can accept credit and debit card payments, as well as alternative payment methods like Apple Pay and Google Pay.',
  },
  [Gateway.SQUARE]: {
    displayName: 'Square',
    fulfilledBy: 'By Square Inc',
    service: Gateway.SQUARE,
    iconUrl: 'https://pipedream.com/s.v0/app_1M0hQd/logo/orig',
    supportedCountries: [],
    supportsGooglePay: true,
    supportsApplePay: true,
    ConfigurationComponent: ({connectRecord, ...props}) => {
      if (!connectRecord) {
        return <OauthConfiguration {...props} />;
      }

      return (
        <SquareUpdateConfiguration {...props} connectRecord={connectRecord} />
      );
    },
    appReadMe:
      '# Square\nSquare is a versatile financial technology company that provides point-of-sale (POS) solutions, payment processing, and business management tools. It simplifies payments for businesses with hardware like card readers, offers e-commerce integration, and helps with employee management and analytics. Square is a one-stop solution for businesses seeking efficient financial services.',
  },
  [Gateway.SHUTTLE]: {
    displayName: 'Shuttle',
    fulfilledBy: 'By Shuttle Global',
    service: Gateway.SHUTTLE,
    iconUrl:
      'https://www.shuttleglobal.com/wp-content/uploads/shuttle_mark_blue_small.png',
    supportedCountries: [],
    supportsGooglePay: false,
    supportsApplePay: false,
    appReadMe:
      '# Shuttle\nShuttle is a payment aggregator which enables merchants to connect to over 40+ different payment gateways including Adyen, Checkout.com, Braintree and PayPal.',
    ConfigurationComponent: props => <ShuttleConfiguration {...props} />,
  },
  [Gateway.PLUTO]: {
    private: true,
    displayName: 'Pluto',
    fulfilledBy: 'By Air Europa',
    service: Gateway.PLUTO,
    iconUrl:
      'https://logodownload.org/wp-content/uploads/2019/10/air-europa-logo-0-768x768.png',
    supportedCountries: [],
    supportsGooglePay: true,
    supportsApplePay: true,
    appReadMe:
      '# Pluto\nPluto is a private internal payment system built by the geniuses at Air Europa',
    ConfigurationComponent: props => <PlutoConfiguration {...props} />,
  },
  [Gateway.WORLDPAY]: {
    displayName: 'WorldPay',
    fulfilledBy: 'Coming Soon',
    service: Gateway.WORLDPAY,
    iconUrl:
      'https://cdn.icon-icons.com/icons2/1487/PNG/512/8412-worldpay_102489.png',
    supportedCountries: [],
    supportsGooglePay: true,
    supportsApplePay: false,
    appReadMe:
      '# WorldPay\nWorldPay is a global leader in payment processing technology and solutions for our merchant customers. We operate reliable and secure proprietary technology platforms that enable merchants to accept a vast array of payment types, across multiple channels, anywhere in the world.',
    ConfigurationComponent: () => (
      <Typography>Integration with WorldPay is coming soon</Typography>
    ),
  },
  [Gateway.BRIDGERPAY]: {
    displayName: 'BridgerPay',
    fulfilledBy: 'By BridgerPay',
    service: Gateway.BRIDGERPAY,
    iconUrl:
      'https://res.cloudinary.com/dvrjmbnri/image/upload/v1739379179/bridgerpay_gjzdjp.webp',
    supportedCountries: [],
    supportsGooglePay: false,
    supportsApplePay: false,
    appReadMe:
      '# BridgerPay\nBridgerPay is a payment operations platform built to scale any business. Easily build, manage, and optimize your connections to any payment gateway or method worldwide.',
    ConfigurationComponent: props => <BridgerPayConfiguration {...props} />,
  },
};
